<template>
  <router-view></router-view>
</template>
<style>
  .black-button {
    background: #eb0029 !important;
    color: #fff !important;
  }
  .black-button:hover {
    background: #fff !important;
    color: #eb0029 !important;
  }
  .white-button {
    background: #fff !important;
    color: #eb0029 !important;
  }
  .white-button:hover {
    background: #eb0029 !important;
    color: #fff !important;
  }
  th .cell {
    font-size: 16px !important;
    color: #eb0029 !important;
    font-weight: 500 !important;
  }
  .pagination.pagination-primary .page-item.active > .page-link {
    background: #eb0029 !important;
    color: #fff !important;
  }
  .boldNumbers {
    font-weight: 700;
  }
  .card-category {
    color: #eb0029 !important;
  }
  .card-collapse .card .card-header a[data-toggle="collapse"] i {
    color: #eb0029 !important;
  }
  #headingOne01 a {
    cursor: pointer;
  }
</style>

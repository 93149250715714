/* eslint-disable */

import axios from "axios";

const instance = axios.create({
  baseURL: "https://imd.phcsoftware.com/api-phc-form/public",
  headers: {
    "content-type": "application/json",
    "Authorization": "Basic YXBpX3ljX3VzZXI6ZTZLLzc1SjMpcFl+c153TA==",
  },
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
});

export default {
  // SIGN IN
  async login(data) {
    try {
      const res = await instance.post("/dashboard/login", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },

  async listarLeads(data) {
    try {
      const res = await instance.post("/dashboard/listar/leads", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async listarLeadsIncumprimento(data) {
    try {
      const res = await instance.post(
        "/dashboard/listar/leads/incumprimento",
        data
      );
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
  async converterLead(data) {
    try {
      const res = await instance.post("/dashboard/converter/leads", data);
      return res.data;
    } catch (err) {
      console.error(err);
      return null;
    }
  },
};

<template>
  <div class="user user__menu">
    <div class="info">
      <a
        data-toggle="collapse"
        href="/"
        role="button"
        aria-controls="userMenu"
        aria-expanded="false"
        style="margin: 10px 15px 0"
      >
        <img src="img/logo_menu.png" />
      </a>
    </div>
  </div>
</template>
<script>
  export default {
    name: "user-menu",
    props: {
      title: {
        type: String,
        default: "James Amos",
      },
      image: {
        type: String,
        default: "img/james.jpg",
      },
    },
  };
</script>
<style>
  .user__menu ul.user-menu__nav {
    margin-top: 0;
    padding-top: 20px;
  }
</style>
